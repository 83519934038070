import React from 'react';

import Main from '../components/Warranty/Main';
import Header from '../components/Warranty/Header';
import Footer from '../components/Footer';
import useCountryLocale from '../hooks/use-country-locale';

export default function WarrantyClub() {
  useCountryLocale('RU', 'ru');
  return (
    <>
      <Header />
      <Main />
      <Footer background="#232323" />
    </>
  );
}
